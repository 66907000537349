.App {
  text-align: center;
}

body {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAOklEQVQImV3KoQ0AQAgEQVqjmauQDpCEDtB0gL6X5BFrJiskcROSmBlUFbp70d2hqsjMxYiAmf3n7QFDxE6ON9qbYgAAAABJRU5ErkJggg==);
  font-family: 'Open Sans',Arial,Helvetica,sans-serif !important;
  text-shadow: 0 0 0;
  font-size: 1em;
}

.container {
  position: relative;
  top: 50px;
  width: 100%;
  background: transparent;
}

.container #content.page {
  padding: 0 2%;
  background-color: #fff;
  box-shadow: -2px 2px 4px #646464;
  position: relative;
  width: 100%;
  margin: 0 auto;
  min-height: 1600px;
  height: 100%;
  display: block;
  float: left;
  clear: both;
}

@media (max-width: 768px) {
  body {
    background: none;
  }

  .container, .container-sm {
    max-width: inherit !important;
  }

  .container #content.page {
    box-shadow: none;
  }

  #aboutMeIntro {
    width: 100% !important;
  }
}

nav {
  height: 50px;
  position: fixed;
  background: url('/public/tropical-leaves-FPWgRFO2.jpg') fixed 5% 90%;
  box-shadow: 0px 2px 4px #646464;
  top: 0;
  width: 100%;
  z-index: 1000;
  text-transform: uppercase;
}

nav .link.active {
  text-decoration: underline;
}

nav .link {
  text-decoration: none;
  display: inline;
  padding-right: 1em;
  font-weight: bold;
  cursor: pointer;
}

.scrollableSection {
  position: relative;
  float: left;
  clear: both;
  width: 100%;
  min-height: 800px;
  /*margin: 2em auto;*/
  margin-bottom: 4em;
}
.h1Container {
  /*clip-path: polygon(0 0, 100% 0, 100% 80%, 0% 100%);*/
  /*background: url('/public/tropical-leaves-FPWgRFO2.jpg') fixed 5% 90%;*/
}

.h1ContainerOverlay {
  background-color: #fff0;
  /*clip-path: polygon(0 0, 100% 0, 100% 80%, 0% 100%);*/
}

.scrollableSection:nth-of-type(even) .h1Container, .scrollableSection:nth-of-type(even) .h1ContainerOverlay {
  /*clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 80%);*/
}

.scrollableSection h1 {
  text-transform: uppercase;
  font-weight: bold;
  padding: .25em;
}

#aboutMePhoto {
  max-width: 200px;
  box-shadow: 0px 2px 6px #646464;
  border-radius: 1em;
  display: block;
  margin: 1em auto;
}

#aboutMeIntro {
  width: 50%;
  margin: auto;
}

#aboutMeBio {
  text-align: left;
  margin-top: 3em;
  padding: .5em;
}

#aboutMeBio h3 {
  margin-top: 1em;
  text-transform: uppercase;
  font-size: 1.3em;
}

#aboutMeBio h3:first-child {
  margin-top: 0;
}

#currentProjectsDetails {
  text-align: left;
  margin-top: 3em;
}

#currentProjectsDetails h3 {
  margin-top: 2.5em;
}

#currentProjectsDetails h3:first-child {
  margin-top: inherit;
}

#currentProjectsDetails #nextgen-logo {
  max-width: 150px;
  float: right;
  margin: 0 0 0 1em;
  box-shadow: 0px 2px 12px #646464;
}

#currentProjects #charts {
  max-width: 150px;
  float: left;
  margin: 0 1em 0 0;
  box-shadow: 0px 2px 12px #646464;
}

#portfolio {
  /*background-color: #e6e6e6;*/
}

#portfolio .accordion {
  /*margin: 2em;*/
}

#portfolio .accordion .accordion-button {
  background-color: #f7f7f7;
}

#portfolio .accordion .accordion-body {
  /*background-color: #f7f7f7;*/
}

#portfolio p, #portfolio h2, #portfolio h3, #portfolio h4, #portfolio h5 {
  text-align: left;
}

#portfolio h3 {
  text-transform: uppercase;
  font-size: 1.3em;
  margin-top: 3em;
}

#portfolio h3:first-child {
  margin-top: inherit;
}

#portfolio h4 {
  color: #646464;
  text-transform: uppercase;
  font-size: 1em;
  margin-top: 1em;
}

#portfolio ul li {
  margin-bottom: .5em;
}

#portfolio h5 {
  color: #646464;
  text-transform: uppercase;
  font-size: .8em;
  font-style: italic;
}

footer {
  position: relative;
  float: left;
  clear: both;
  width: 100%;
  min-height: 300px;
  margin-top: 3em;
  background: url('/public/tropical-leaves-FPWgRFO2.jpg') fixed 5% 90%;
  box-shadow: 0px -2px 4px #646464;
}

#footerOverlay {
  background-color: #fff0;
  position: relative;
  float: left;
  clear: both;
  width: 100%;
  min-height: 200px;
}

footer p {
  margin-top: 2em;
}